<script setup lang="ts">
import type {AnimationObj} from "@/Pages/Welcome.vue";

interface Props {
  fadeUp: AnimationObj;
}

const props = defineProps<Props>();

const faqs: { id: string; targetId: string; expanded: boolean; question: string; answer: string }[] = [
  {
    id      : 'paymentMethods',
    targetId: 'collapsePaymentMethods',
    expanded: true,
    question: 'What payment methods do you accept?',
    answer  : 'Currently, we accept payments via cryptocurrency. We are actively working on integrating additional payment methods, including credit cards and other payment options. If you encounter any issues with payment or have questions, please reach out to our support team for assistance.'
  },
  {
    id      : 'accuracyGuarantee',
    targetId: 'collapseAccuracyGuarantee',
    expanded: false,
    question: 'What is the accuracy guarantee?',
    answer  : 'We guarantee 97% accuracy in our results. If you find that the accuracy is below this, please report it to us within 24 hours, including details of the inaccurate results, and we will refund the credits consumed for those verifications.'
  },
  {
    id      : 'unknownRefunds',
    targetId: 'collapseUnknownRefunds',
    expanded: false,
    question: 'What happens if there are duplicates or the verification status is unknown?',
    answer  : 'You only pay for successfully verified emails. We automatically refund credits for any duplicate emails in your file and for emails where the verification status is unknown.'
  },
  {
    id      : 'freeTier',
    targetId: 'collapseFreeTier',
    expanded: false,
    question: 'How does the free tier work?',
    answer  : 'We believe in giving everyone a fair start with email marketing. That’s why we offer 500 free credits every month. These credits reset monthly, and on the 1st of each month, you’ll receive enough credits to bring your balance back up to 500.'
  },
  {
    id      : 'dataSecurity',
    targetId: 'collapseDataSecurity',
    expanded: false,
    question: 'Is my data safe?',
    answer  : 'Absolutely! Your data remains completely confidential. In rare cases where manual review is necessary, only the relevant portion of your data can be accessed by authorized personnel. All uploaded data is automatically deleted 30 days after the last verification.'
  },
  //{id: '', targetId: '', expanded: false, question: '', answer: ''},
];
</script>

<template>
  <div class="faqs-area faqs-area--inner bg-default-6">
    <div class="container">
      <div class="row justify-content-center">
        <div class="section-title section-title--l7 text-center">
          <MotionGroup :initial="fadeUp.initial"
                       :visible-once="fadeUp.visibleOnce"
                       :duration="fadeUp.duration"
          >
            <h6 class="section-title__sub-heading text-readical-red"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
            >
              FAQ
            </h6>
          </MotionGroup>
          <MotionGroup :initial="fadeUp.initial"
                       :visible-once="fadeUp.visibleOnce"
                       :delay="300"
                       :duration="fadeUp.duration"
          >
            <h2 class="section-title__heading">
              Frequently Asked Question
            </h2>
          </MotionGroup>
        </div>
      </div>
      <MotionGroup :initial="fadeUp.initial"
                   :visible-once="fadeUp.visibleOnce"
                   :delay="700"
                   :duration="fadeUp.duration"
      >
        <div class="tab-content">
          <!-- single Accordion -->
          <div id="pricingFAQ"
               class="accordion accordion--inner"
          >
            <div v-for="(faq, index) of faqs"
                 :key="index"
                 class="accordion-item"
            >
              <h2 :id="faq.id"
                  class="accordion-header"
              >
                <button class="accordion-button"
                        :class="faq.expanded? '' : 'collapsed' "
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#' + faq.targetId"
                        :aria-expanded="faq.expanded"
                        :aria-controls="faq.targetId"
                >
                  {{ faq.question }}
                </button>
              </h2>
              <div :id="faq.targetId"
                   class="accordion-collapse collapse"
                   :class="faq.expanded? 'show' : ''"
                   :aria-labelledby="faq.targetId"
                   data-bs-parent="#pricingFAQ"
              >
                <div class="accordion-body">
                  {{ faq.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MotionGroup>
    </div>
  </div>
</template>

<style scoped>

</style>
