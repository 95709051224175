<script setup lang="ts">
import Carousel, {type CarouselResponsiveOptions} from "primevue/carousel";
import {ref} from "vue";
import type {AnimationObj} from "@/Pages/Welcome.vue";

interface Props {
  fadeUp: AnimationObj;
}

const props = defineProps<Props>();

const testimonials = [
  {
    name   : 'Ethan Caldwell',
    message: 'Over the past year, we\'ve tested several email verification services, but this one stands out...',
  },
  {
    name   : 'Olivia Marshall',
    message: 'I started with the free tier and within a few months I was able to scale my operations up.',
  },
  {
    name   : 'Noah Bennett',
    message: 'Customer Service was good and took the time to explain what I could do better.',
  },
  {
    name   : 'Liam Foster',
    message: 'I was a long time user of another verifier and was invited for the beta run...',
  },
  {
    name   : 'Ava Cooper',
    message: 'Affordable service and accurate results.',
  },
];
const sliderResponsiveSettings = ref<CarouselResponsiveOptions[]>([
  {breakpoint: '1200px', numVisible: 3, numScroll: 3,},
  {breakpoint: '991px', numVisible: 2, numScroll: 2,},
  {breakpoint: '767px', numVisible: 1, numScroll: 1,},
]);

</script>

<template>
  <div class="testimonial-section testimonial-section--l4 bg-default-3">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
          <div class="section-title section-title--l4">
            <MotionGroup :initial="fadeUp.initial"
                         :visible-once="fadeUp.visibleOnce"
                         :duration="fadeUp.duration"
            >
              <h6 class="section-title__sub-heading text-readical-red">
                Testimonial
              </h6>
            </MotionGroup>
            <MotionGroup :initial="fadeUp.initial"
                         :visible-once="fadeUp.visibleOnce"
                         :delay="300"
                         :duration="fadeUp.duration"
            >
              <h2 class="section-title__heading mb-4">
                Don’t Just Take Our Words For It
              </h2>
            </MotionGroup>
          </div>
        </div>
      </div>
      <div class="row justify-content-center testimonial-slider-l4">
        <MotionGroup :initial="fadeUp.initial"
                     :visible-once="fadeUp.visibleOnce"
                     :delay="700"
                     :duration="fadeUp.duration"
        >
          <Carousel :value="testimonials"
                    :show-navigators="true"
                    :show-indicators="true"
                    :num-visible="3"
                    :num-scroll="3"
                    :responsive-options="sliderResponsiveSettings"
                    circular
          >
            <template #item="slotProps">
              <div class="card card-testimonial--l4">
                <div class="card-body ">
                  <div class="card-body__top d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-wrap align-items-center">
                      <div class="card-body__user mr-3">
                        <h3 class="card-title">
                          <strong>{{ slotProps.data.name }}</strong>
                        </h3>
                      </div>
                    </div>
                    <div class="card-icon">
                      <i class="fa fa-quote-left" />
                    </div>
                  </div>
                  <p class="card-description">
                    {{ slotProps.data.message }}
                    <!-- the results with our own mailing, and the difference is clear.-->
                  </p>
                </div>
              </div>
            </template>
          </Carousel>
        </MotionGroup>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
