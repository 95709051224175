<script setup lang="ts">
import {Head} from "@inertiajs/inertia-vue3";
import {onMounted, ref} from "vue";
import jquery from "jquery";
import 'jquery-migrate';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/home/css/bootstrap.css';

import '../../css/home/fonts/icon-font/css/style.css'
import '../../css/home/fonts/typography-font/typo.css'
import '../../css/home/fonts/fontawesome-5/css/all.css'
import '../../css/home/css/main.css';

import HeaderNavBarHome from "@/Components/Home/HeaderNavBarHome.vue";
import Hero from "@/Components/Home/Hero.vue";
import OurAdvantage from "@/Components/Home/OurAdvantage.vue";
import HowItWorks from "@/Components/Home/HowItWorks.vue";
import WhyUs from "@/Components/Home/WhyUs.vue";
import Pricing from "@/Components/Home/Pricing.vue";
import FAQ from "@/Components/Home/FAQ.vue";
import Testimonial from "@/Components/Home/Testimonial.vue";
import CallToActionTwo from "@/Components/Home/CallToActionTwo.vue";
import Footer from "@/Components/Home/Footer.vue";
import {useApi} from "@/Composables/requestApi.js";

export interface AnimationObj {
  initial: { opacity: number, y?: number, x?: number },
  visibleOnce: { opacity: number, y?: number, x?: number },
  delay: string; //"100",
  duration: string; //"300"
}

export interface Product {
  id: number;
  name: "500 Verifications";
  credits: number;
  price: number;
  currency: "USD" | "INR";
}

interface Props {

}

window.jQuery = window.$ = jquery;

const props = defineProps<Props>();
// loading
const loading = ref(true);

const onLoad = () => {
  loading.value = false;
  window.removeEventListener('load', onLoad);
}

onMounted(() => {
  if (document.readyState === 'complete') {
    onLoad();
  } else {
    window.addEventListener('load', () => {
      onLoad();
    });
  }
  getProducts()

})
const products = ref<Product[]>([]);
const ccy = ref<string>('USD');
const fetchingProducts = ref(false);

const getProducts = async () => {
  fetchingProducts.value = true;
  const response = await useApi<{ currency: string, products: Product[] }>(route('products.list', 'USD'), "get");
  products.value = response.responseData.value ? response.responseData.value.products : [];
  ccy.value = response.responseData.value?.currency || '';
  fetchingProducts.value = false;

}

const fadeUp = {
  initial    : {opacity: 0, y: 100},
  visibleOnce: {opacity: 1, y: 0},
  delay      : "300",
  duration   : "500"
}

const fadeLeft = {
  initial    : {opacity: 0, x: 100}, // <!-- Starts 50px to the right -->
  visibleOnce: {opacity: 1, x: 0},
  delay      : "300",
  duration   : "500"
}

const fadeRight = {
  initial    : {opacity: 0, x: 100}, // <!-- Starts 50px to the right -->
  visibleOnce: {opacity: 1, x: 0},
  delay      : "300",
  duration   : "500"
}

</script>

<template>
  <!--
<link rel="stylesheet" href="/home/css/bootstrap.css">
      <link rel="stylesheet" href="/home/fonts/icon-font/css/style.css">
      <link rel="stylesheet" href="/home/fonts/typography-font/typo.css">
<link rel="stylesheet" href="/home/fonts/fontawesome-5/css/all.css">

<link rel="stylesheet" href="/home/plugins/fancybox/jquery.fancybox.min.css">
<link rel="stylesheet" href="/home/plugins/nice-select/nice-select.min.css">
<link rel="stylesheet" href="/home/plugins/slick/slick.min.css">
<link rel="stylesheet" href="/home/css/main.css">
<link rel="stylesheet" href="/home/plugins/aos/aos.min.css">
<link rel="stylesheet" href="/home/plugins/theme-mode-switcher/switcher-panel.css">
  -->

  <Head>
    <title>UltimateEmailVerifier</title>
    <meta name="description"
          content="SMTP Email Verifier service with grey listing and ip rotation."
    >
  </Head>

  <!-- todo : 1. Actual Pricing, 2. Call To Action Process    -->

  <div class="site-wrapper overflow-hidden ">
    <div v-if="loading"
         id="loading"
    >
      <img src="/home/image/preloader.gif"
           alt=""
      >
    </div>
    <div :class="loading? 'loading-hidden' : ''">
      <HeaderNavBarHome />
      <Hero :fade-up="fadeUp" />
      <OurAdvantage :fade-up="fadeUp" />
      <HowItWorks :fade-up="fadeUp" />
      <WhyUs :fade-up="fadeUp"
             :fade-left="fadeLeft"
      />
      <Pricing :fetching-products="fetchingProducts"
               :products="products"
               :ccy="ccy"
               :fade-up="fadeUp"
               :fade-left="fadeLeft"
      />
      <FAQ :fade-up="fadeUp" />
      <Testimonial :fade-up="fadeUp" />
      <CallToActionTwo :fade-up="fadeUp" />

      <!-- Footer Area -->
      <Footer />
      <!--/ .Footer Area -->
    </div>
  </div>

  <!-- Plugin's Scripts -->
  <!--    <component is="script" src="/home/plugins/jquery/jquery.min.js"></component> -->
  <!--    <component is="script" src="/home/plugins/jquery/jquery-migrate.min.js"></component>-->
  <!--    <component is="script" src="/home/js/bootstrap.bundle.js"></component>-->
  <!--    <component is="script" src="/home/plugins/fancybox/jquery.fancybox.min.js"></component>-->
  <!--    <component is="script" src="/home/plugins/nice-select/jquery.nice-select.min.js"></component>-->
  <!--    <component is="script" src="/home/plugins/aos/aos.min.js"></component>-->
  <!--    <component is="script" src="/home/plugins/counter-up/jquery.counterup.min.js"></component>-->
  <!--    <component is="script" src="/home/plugins/counter-up/waypoints.min.js"></component>-->
  <!--    <component is="script" src="/home/plugins/slick/slick.min.js"></component>-->
  <!--    <component is="script" src="/home/plugins/skill-bar/skill.bars.jquery.js"></component>-->
  <!--    <component is="script" src="/home/plugins/isotope/isotope.pkgd.min.js"></component>-->
  <!--<script src="./plugins/theme-mode-switcher/gr-theme-mode-switcher.js"></script>-->
  <!-- Activation Script -->
  <component is="script"
             src="/home/js/menu.js"
  />
  <component is="script"
             src="/home/js/custom.js"
  />
</template>

<style scoped>
.loading-hidden {
  display: none;;
}

div#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

div#loading img {
  z-index: 9999;
  width: 25%;
}

img, svg {
  vertical-align: middle;
}

@media (min-width: 576px) {
  div#loading img {
    width: 17%;
  }
}

@media (min-width: 768px) {
  div#loading img {
    width: 15%;
  }
}

@media (min-width: 992px) {
  div#loading img {
    width: 7%;
  }
}

</style>
