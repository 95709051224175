<script setup lang="ts">


import type {AnimationObj} from "@/Pages/Welcome.vue";

interface Props {
  fadeUp: AnimationObj
}

const props = defineProps<Props>()


const benefits = ['Improved Sender Reputation', 'Reduced Bounces', 'Reduced Cost', 'Increase Efficiency']
</script>

<template>
  <div class="welcome-area welcome-area--l7 bg-default position-relative dark-mode-texts hero-bg">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-9">
          <div class="welcome-area___content text-center">
            <MotionGroup :initial="fadeUp.initial"
                         :visible-once="fadeUp.visibleOnce"
                         :duration="fadeUp.duration"
            >
              <span class="welcome-area--l7__icon"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-once="true"
              >
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512"
                     style="width: 1.5rem; fill: #fd346e;"
                >
                  <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                  <path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0z" />
                </svg>
              </span>
            </MotionGroup>
            <MotionGroup :initial="fadeUp.initial"
                         :visible-once="fadeUp.visibleOnce"
                         :duration="fadeUp.duration"
                         :delay="50"
            >
              <h1 class="welcome-area--l7__heading"

                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="50"
                  data-aos-once="true"
              >
                Email Verification - The Way It Should Be Done.
              </h1>
            </MotionGroup>
            <MotionGroup :initial="fadeUp.initial"
                         :visible-once="fadeUp.visibleOnce"
                         :duration="fadeUp.duration"
                         :delay="100"
            >
              <p class="welcome-area--l7__descriptions"
                 data-aos="fade-up"
                 data-aos-duration="500"
                 data-aos-delay="100"
                 data-aos-once="true"
              >
                We remove invalid emails from your mailing lists. <br class="d-block">
                <span style="display: inline; color:white">
                  Experience
                  <VueWriter :array="benefits" />
                </span>
                <br class="d-block">
                Get Started with our generous free tier.
              </p>
            </MotionGroup>
            <MotionGroup :initial="fadeUp.initial"
                         :visible-once="fadeUp.visibleOnce"
                         :duration="fadeUp.duration"
                         :delay="150"
            >
              <a class="btn btn--lg-2 btn-readical-red rounded-50 text-white"
                 href="/register"
                 data-aos="fade-up"
                 data-aos-duration="500"
                 data-aos-delay="150"
                 data-aos-once="true"
              >Get Started</a>
            </MotionGroup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.is-typed {
  display: inline;
}

.hero-bg {
  background-image: url(/home/image/hero-bg.webp);
}
</style>
