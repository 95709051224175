<script setup lang="ts">

import {computed, onMounted, ref, toRefs, watch} from "vue";
import type {AnimationObj, Product} from "@/Pages/Welcome.vue";
import CounterUp from "@/Components/CounterUp.vue";
import Skeleton from 'primevue/skeleton';
import Button from "primevue/button";

interface Props {
  fetchingProducts: boolean;
  products: Product[]
  ccy: string;
  fadeUp: AnimationObj;
  fadeLeft: AnimationObj;
}

const props = defineProps<Props>();

const firstLoad = ref(true);
const pricingMotion = ref({
  initial    : props.fadeLeft.initial,
  visibleOnce: props.fadeLeft.visibleOnce,
  delay      : 500,
  duration   : props.fadeLeft.duration
});


const guaranteedAccuracyPercent = 98
const {fetchingProducts} = toRefs(props);
const hidePrices = ref(true)
onMounted(() => {
  console.log(props.fetchingProducts);
  if (props.fetchingProducts) {
    hidePrices.value = true;
  } else {
    hidePrices.value = false;
  }
})

/** First price should be free tier */
const prices = ref<{ count: number, price: string }[]>([]);
const noOfEmailsToVerify = ref<number | undefined>(undefined)

const onPillClick = (value: number) => {
  noOfEmailsToVerify.value = value;
}

const price = computed(() => {
  let priceConf = null;
  for (const p of prices.value) {
    priceConf = p;
    if (p.count >= (noOfEmailsToVerify.value || 0)) {
      break;
    }

  }
  const priceInt = !priceConf ? 0 : ((Number(priceConf.price) / priceConf.count) * (noOfEmailsToVerify.value || 1))
  return priceInt.toLocaleString('en', {
    style                : 'currency',
    currency             : props.ccy,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
})

watch(fetchingProducts, (newValue, oldValue) => {
  if (newValue) hidePrices.value = true;
  if (!newValue && oldValue) {
    prices.value = props.products.map(p => {
      return {
        count: p.credits, price: (p.price / 100).toFixed(2)
      }
    });
    if (!noOfEmailsToVerify.value) noOfEmailsToVerify.value = props.products[0].credits;
    hidePrices.value = false;
  }
});

</script>

<template>
  <div id="pricing"
       class="container"
  >
    <div class="row align-items-center justify-content-center">
      <div class="surface-section px-4 py-8 px-md-6 px-lg-8">
        <div class="row">
          <!--Section Title -->
          <div class="col-12 p-1 mb-3 mb-lg-5">
            <MotionGroup :initial="fadeUp.initial"
                         :visible-once="fadeUp.visibleOnce"
                         :duration="fadeUp.duration"
            >
              <h6 class="section-title__sub-heading text-readical-red"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-once="true"
              >
                What is the price
              </h6>
            </MotionGroup>
            <MotionGroup :initial="fadeUp.initial"
                         :visible-once="fadeUp.visibleOnce"
                         :delay="300"
                         :duration="fadeUp.duration"
            >
              <h2 class="section-title__heading"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="300"
                  data-aos-once="true"
              >
                Pricing
              </h2>
            </MotionGroup>
          </div>

          <!--Left Info Box-->
          <div class="col-12 col-lg-6 mb-md-3">
            <div class="h-100">
              <div class="d-flex flex-column align-items-center justify-content-center h-100"
                   style="border-radius: 6px"
              >
                <MotionGroup :initial="fadeUp.initial"
                             :visible-once="fadeUp.visibleOnce"
                             :delay="500"
                             :duration="fadeUp.duration"
                >
                  <!-- Accuracy Guaranteed -->
                  <div class="d-flex flex-row p-3 mb-3">
                    <span class="d-inline-flex align-items-center justify-content-center text-white bg-flamingo shadow--flamingo rounded-circle"
                          style="min-width: 2.25rem; height: 2.25rem;"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 512 512"
                           style="fill: #ffff; width: 0.95rem;"
                      >
                        <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0z" />
                      </svg>
                    </span>
                    <div class="ml-3">
                      <div class="text-900 font-medium text-xl mb-2">
                        {{
                          guaranteedAccuracyPercent
                        }}%
                        Accuracy Guaranteed
                      </div>
                      <span class="text-600 line-height-3">
                        We ensure {{ guaranteedAccuracyPercent }}% accuracy in our results. If we fall short, simply let us know, and we'll provide a refund—no questions asked.
                      </span>
                    </div>
                  </div>

                  <!-- Data Security & Privacy -->
                  <div class="flex flex-row p-3 mb-3">
                    <span class="d-inline-flex align-items-center justify-content-center bg-blue-ribbon shadow--blue-ribbon-2 text-white border-circle"
                          style="min-width: 2.25rem; height: 2.25rem"
                    >
                      <i class="fas fa-lock" />
                    </span>
                    <div class="ml-3">
                      <div class="text-900 font-medium text-xl mb-2">
                        Data Security & Privacy
                      </div>
                      <span class="text-600 line-height-3">
                        Your uploaded data is handled with strict confidentiality. Only authorized personnel can access specific portions required for verification. We never share your data with third parties, and all data is automatically deleted 30 days after verification.
                      </span>
                    </div>
                  </div>

                  <!-- Credits Never Expire -->
                  <div class="flex flex-row p-3">
                    <span class="d-inline-flex align-items-center justify-content-center text-white bg-egg-blue shadow--egg-blue border-circle"
                          style="min-width: 2.25rem; height: 2.25rem"
                    >
                      <i class="fas fa-wallet" />
                    </span>
                    <div class="ml-3">
                      <div class="text-900 font-medium text-xl mb-2">
                        Credits Never Expire
                      </div>
                      <span class="text-600 line-height-3">
                        Purchased Verification Credits never expire. You can buy as much as you want use them for as long as you need.
                      </span>
                    </div>
                  </div>

                  <!--Free Tier-->
                  <div class="flex flex-row p-3 mb-3">
                    <span class="d-inline-flex align-items-center justify-content-center bg-flamingo text-white shadow--flamingo border-circle"
                          style="min-width: 2.25rem; height: 2.25rem"
                    >
                      <i class="fas fa-gift" />
                    </span>
                    <div class="ml-3">
                      <div class="text-900 font-medium text-xl mb-2">
                        Forever Free Tier
                      </div>
                      <span class="text-600 line-height-3">
                        Verify up to {{
                          prices[0] && Number(prices[0].price) === 0 ? prices[0].count.toLocaleString() : "---"
                        }} emails per month for free. Enjoy unlimited access with no expiration—no restrictions or hidden fees.
                      </span>
                    </div>
                  </div>
                </MotionGroup>
              </div>
            </div>
          </div>

          <!--Pricing-->
          <div class="col-12 col-lg-6">
            <div class="h-100 pl-3">
              <div class="surface-card shadow p-5"
                   style="border-radius: 6px"
              >
                <div class="d-flex flex-column justify-content-between flex-md-row">
                  <!--Price Display-->
                  <div class="flex-grow-1 w-lg-auto w-lg-min">
                    <div class="text-900 font-bold text-2xl mb-2">
                      <span v-if="hidePrices">
                        <Skeleton height="2rem"
                                  width="7rem"
                        />
                      </span>
                      <span v-else-if="!firstLoad">
                        {{ Number(noOfEmailsToVerify).toLocaleString() }} Emails
                      </span>
                      <span v-else>
                        <CounterUp :delay="Number(fadeUp.duration) || 0"
                                   :end-value="Number(noOfEmailsToVerify)"
                                   :duration-in-seconds="1"
                                   :suffix="' Emails'"
                                   @complete="firstLoad=false"
                        />
                      </span>
                    </div>
                  </div>
                  <div v-if="hidePrices"
                       class="d-flex align-items-center w-auto w-lg-9rem justify-content-lg-end "
                  >
                    <Skeleton height="2rem"
                              width="5rem"
                    />
                  </div>
                  <div v-else
                       class="d-flex align-items-center w-auto w-lg-9rem justify-content-lg-end "
                  >
                    <span class="font-bold text-4xl text-900">{{ price }}</span>
                    <span class="text-700 text-xl ml-2 line-height-3">one time</span>
                  </div>
                </div>


                <!--Text Input Box for No Of Emails-->
                <div class="d-flex justify-content-between align-items-center mt-3">
                  <div v-if="hidePrices"
                       class="w-full mb-2"
                  >
                    <Skeleton height="3rem"
                              width="100%"
                    />
                  </div>
                  <div v-else
                       class="w-full mb-2"
                  >
                    <div class="form-floating w-full">
                      <input id="floatinginput"
                             v-model="noOfEmailsToVerify"
                             class="form-control"
                      >
                      <label for="floatinginput"
                             style="opacity: 1 !important; font-weight: 500;"
                      >
                        How many Emails Would You Verify</label>
                    </div>
                  </div>
                </div>

                <!--Range Slider-->
                <div class="d-flex mb-2">
                  <Skeleton v-if="hidePrices"
                            width="100%"
                            height="0.5rem"
                  />
                  <input v-else
                         id="customRange2"
                         v-model="noOfEmailsToVerify"
                         type="range"
                         class="form-range"
                         min="100"
                         max="1000000"
                  >
                </div>

                <!--Display Pills -->
                <Skeleton v-if="hidePrices"
                          height="3rem"
                          width="100%"
                />
                <div v-else
                     class="d-flex flex-wrap gap-2"
                >
                  <span v-for="(price, index) of prices"
                        :key="index"
                        class="badge border border-primary text-primary bg-transparent cursor-pointer p-2 text-lg"
                        @click="onPillClick(price.count)"
                  >
                    {{ price.count.toLocaleString() }}
                  </span>
                </div>

                <hr class="my-5 mx-0 border-top border-2 border-none surface-border">

                <div class="text-900 font-medium text-xl pb-1">
                  Features
                </div>

                <div class="d-flex flex-column justify-space-between flex-md-row">
                  <ul class="list-none p-0 m-0 flex-grow-1">
                    <li class="flex align-items-center my-3">
                      <i class="pi pi-check-circle text-green-500 mr-3" />
                      <span>Syntax Check</span>
                    </li>
                    <li class="flex align-items-center my-3">
                      <i class="pi pi-check-circle text-green-500 mr-3" />
                      <span>Disposable Emails Removed</span>
                    </li>
                    <li class="flex align-items-center my-3">
                      <i class="pi pi-check-circle text-green-500 mr-3" />
                      <span>Role Based Emails Flagged</span>
                    </li>
                    <li class="flex align-items-center my-3">
                      <i class="pi pi-check-circle text-green-500 mr-3" />
                      <span>DNS Check</span>
                    </li>
                  </ul>
                  <ul class="list-none p-0 md:pl-2 m-0 flex-grow-1">
                    <li class="flex align-items-center my-3">
                      <i class="pi pi-check-circle text-green-500 mr-3" />
                      <span>SMTP Check</span>
                    </li>
                    <li class="flex align-items-center my-3">
                      <i class="pi pi-check-circle text-green-500 mr-3" />
                      <span>Catch All or Accept All Check</span>
                    </li>
                    <li class="flex align-items-center my-3">
                      <i class="pi pi-check-circle text-green-500 mr-3" />
                      <span>Spam Trap Removal</span>
                    </li>
                    <li class="flex align-items-center my-3">
                      <i class="pi pi-check-circle text-green-500 mr-3" />
                      <span>Domain Checks</span>
                    </li>
                  </ul>
                </div>

                <hr class="my-3 mx-0 border-top-1 border-none surface-border">

                <Button class="w-full mt-2"
                        label="Buy Now"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 992px) {
  .w-lg-min {
    width: min-content !important;
  }
}

</style>
