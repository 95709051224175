<script setup lang="ts">
import CountUp, {type CountUpOptions, type ICountUp} from "vue-countup-v3";
import {ref, watch} from "vue";
import {useIntersectionObserver} from "@vueuse/core";

interface Props {
  endValue: number;
  delay: number;
  durationInSeconds: number;
  enableScrollSpy?: boolean // start animation when target is in view 在可视范围内才开始动画
  scrollSpyDelay?: number // delay (ms) after target comes into view  目标进入可视范围内后的延迟时间(毫秒)
  scrollSpyOnce?: boolean; // run only once
  prefix?: string;
  suffix?: string;
}

const props = defineProps<Props>();
const emit = defineEmits(['complete']);

const options: CountUpOptions = {
  onCompleteCallback() {
    emit('complete')
  },
};

if (props.enableScrollSpy) options.enableScrollSpy = props.enableScrollSpy;
if (props.scrollSpyDelay) options.scrollSpyDelay = props.scrollSpyDelay;
if (props.scrollSpyOnce) options.scrollSpyOnce = props.scrollSpyOnce;
if (props.prefix) options.prefix = props.prefix;
if (props.suffix) options.suffix = props.suffix;

const countUpRef = ref<InstanceType<typeof CountUp>>()
let countUpCtx: ICountUp | undefined;

const counterUpElem = ref(null)
const elemVisible = ref(false)
const {stop} = useIntersectionObserver(
    counterUpElem,
    ([{isIntersecting}], observerElement) => {
      elemVisible.value = isIntersecting
    },
)

watch(elemVisible, () => {
  stop();
  setTimeout(() => {countUpCtx?.start();}, props.delay)
})

const onInit = (ctx: ICountUp) => {
  countUpCtx = ctx;
}

</script>

<template>
  <span ref="counterUpElem">
    <count-up ref="countUpRef"
              :autoplay="false"
              :end-val="endValue"
              :delay="delay"
              :duration="durationInSeconds"
              :options="options"
              @init="onInit"
    />
  </span>
</template>

<style scoped>

</style>
